.estimateStateSelect {
  width: 18rem;
}

.option {
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2.5rem;

  &:hover,
  &.active {
    background: #4fb2ed;
    color: #fff;

    .name {
      max-width: 10rem;
    }

    .actions {
      display: flex;
    }
  }
}

.name {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions {
  display: none;
  gap: 0.25rem;

  svg {
    width: 1rem;
  }
}

.inputWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.25rem;
  align-items: center;
  padding: 0 0.25rem;
}
