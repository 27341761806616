.outer,
.more {
  width: 8.3rem;
}

.intervalBlock {
  background-color: #daeefa;
  border-radius: 1rem;
  font-size: 0.7rem;
  padding: 0.3rem 0.6rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem 0.2rem 0.25rem;
  cursor: pointer;
  position: relative;
  width: 100%;
  white-space: nowrap;
  animation: fading 0.3s ease;

  &.blue {
    background-color: #daeefa;

    & .devider {
      background-color: #5dbcd8;
    }
  }

  &.green {
    background-color: #bbe8e1;

    & .devider {
      background-color: #7ec3b8;
    }
  }

  &.lightGreen {
    background-color: #c9eda0;

    & .devider {
      background-color: #85d529;
    }
  }

  &:hover {
    background-color: #4fb2ed;
    color: #fff;

    .infoPopup {
      display: block;
    }
  }

  &:hover .devider {
    background-color: #fff;
  }

  .devider {
    width: 1px;
    height: 100%;
    margin: 0 0.3rem;
    background-color: #5dbcd8;
    flex-shrink: 0;
  }

  .infoPopup {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    z-index: 9;
    width: 21.75rem;
    color: #000;
    background-color: #fff;
    border: 1px solid var(--color-border-primary);
    border-radius: 0.6rem;
    display: none;

    .arrow {
      position: absolute;
      top: -0.54rem;
      left: 16%;
      width: 1rem;
      height: 1rem;
      border-left: 1px solid var(--color-border-primary);
      border-top: 1px solid var(--color-border-primary);
      transform: rotate(45deg);
      background: #fff;
    }

    .topBlock {
      padding: 1.34rem 1.125rem;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--color-ordinary-line);

      .item {
        width: 50%;
        margin-bottom: 0.625rem;
      }

      .name {
        margin-bottom: 0.625rem;
        background: var(--color-background-field1);
        white-space: nowrap;
        padding: 0.36rem 0.68rem;
        border-radius: 1.5rem;
      }
    }

    .bottomBlock {
      padding: 1.34rem 1.125rem;

      .titleRow {
        display: flex;
        margin-bottom: 0.8rem;

        .item {
          color: var(--color-font-secondary);
          width: 33%;
        }
      }

      .dataRow {
        display: flex;
        align-items: center;
        background: var(--color-background-field2);
        border-radius: 0.5rem;
        height: 2.37rem;
        padding: 0 0.55rem;
        margin-bottom: 0.8rem;

        .item {
          width: 33%;
        }
      }
    }
  }
}

.more {
  @extend .intervalBlock;
  background: #ebebeb;

  & img {
    margin-left: 0.5rem;
  }
}

.bar {
  background-color: #ebebeb;
  height: 5px;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.percents {
  background: #4fb2ed;
  height: 100%;
  border-radius: 3px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.2rem;
  margin-left: auto;
  margin-right: auto;
}

.completed {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.blue {
    & path {
      fill: #8ebfd9;
    }
  }

  &.green {
    & path {
      fill: #acb26a;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%) translateX(70%);
  flex-shrink: 0;
  width: 0.8rem;
}

.date {
  flex-shrink: 0;
}

.count {
  flex-grow: 2;
  text-align: center;
}

@media screen and (max-width: 1841px) {
  .intervalBlock {
    font-size: 0.7rem;
  }

  .more {
    font-size: 0.7rem;
  }

  .outer {
    width: 7.5rem;
  }

  .devider {
    margin: 0 0.1rem !important;
  }
}

@media screen and (max-width: 1640px) {
  .arrow {
    display: none;
  }
}

@media screen and (max-width: 1441px) {
  .arrow {
    display: block;
  }
}

@media screen and (max-width: 1439px) {
  .arrow {
    transform: translateY(-50%) translateX(90%);
    display: none;
  }
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
