.expendituresHeader {
  display: grid;
  grid-template-columns: 3% 34% 13.5% 7% 7% 7% 10.5% 5% 5% 11%;
  height: 3rem;
  font-weight: 500;
}

.expHeaderContainer {
  height: 3rem;
  position: sticky;
  z-index: 1;
  top: 12.5rem;
}

.sectionWrapper {
  & .sectionRow {
    position: sticky;
    z-index: 1;
    top: 9rem;
    background-color: #fff !important;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.sectionRow {
  display: grid;
  grid-template-columns: 4% 60% 12% 12% 12%;
}

.sectionCheckbox {
  & label {
    margin-left: 0;
  }
}

.head {
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 2.8rem;
}

.number {
  & > div {
    text-overflow: unset !important;
  }
}
