.form {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ebebeb;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.checks {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0 1rem;
}

.actions {
  display: flex;
  justify-content: end;
  align-self: center;
  grid-column: -2 / -1;
}

.row1 {
  display: grid;
  grid-template-columns: 55% 8% 25% 10%;
  width: 100%;
}

.row2 {
  display: grid;
  grid-template-columns: 25% 12% 18% 8% 25% 10%;
  width: 100%;
}

.select {
  grid-column: 3/4;
}

.coefficient {
  grid-column: 5/6;
}

.clarification {
  height: min-content;
  white-space: nowrap;
}

.clarificationOptions {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@media (max-width: 1120px) {
  .row1 {
    grid-template-columns: 55% 3% 30% 10%;
  }

  .row2 {
    grid-template-columns: 25% 12% 18% 3% 30% 10%;
  }
}
