.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  &.fixed {
    position: fixed;
  }

  &.static {
    position: static;
  }

  .smallGray {
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid transparent;
    border-bottom-color: #B1B6BB;
    border-left-color: #B1B6BB;
    border-radius: 100%;
    animation: spin infinite linear 1s;
  }

  .spinner {
    width: 4rem;
    height: 4rem;
    border: 0.4rem solid #00b4f2;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 100%;
    animation: spin infinite linear 1s;
  }

  .small {
    width: 2rem;
    height: 2rem;
    border-width: 2px;
  }

  .white {
    border-color: #FFF;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}