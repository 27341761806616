.sectionTitleClassName {
  padding: 0.5rem 0;
  border-bottom: 1px solid #f4f4f4;
  font-weight: 400;
  width: 100%;
}

.subSectionTitleClassName {
  padding: 0.5rem 0;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.checkbox {
  margin-right: 1rem;
}

.row {
  padding: 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subSection {
  padding-left: 1rem;
}

.section {
  padding-left: 1rem;
}
