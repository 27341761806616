.wrapper {
  background-color: #fff !important;
  border-radius: 0 !important;
  height: 6rem;

  &:not(:last-child):not(.withoutBorder) {
    border-bottom: 1px solid #d6d6d6;
  }
}

.row {
  height: 6rem;
  display: grid;

  grid-template-columns: 3% 34% 13.5% 7% 7% 7% 10.5% 5% 5% 11%;
}

.rowInKs2 {
  grid-template-columns: 3% 46% 14.4% 8% 8% 8% 15.5%;
}

.checkContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.3rem;
  width: 1.5rem;
  & > span {
    display: block;
    margin-bottom: 0.7rem;
  }

  & label {
    margin-left: 0 !important;
  }
}

.nameCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.justification {
  font-size: 13px !important;
}

.nameBottom {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 1rem;
}

.name {
  width: 100%;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: unset !important;
}

.ks {
  position: relative;

  & > span {
    position: absolute;
    left: 0;
    bottom: -1.2rem;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(.activeKs) {
    & path {
      fill: #888;
      stroke: #888;
    }
  }
}

.activeKs {
  cursor: pointer;
}

.parent {
  display: grid;
  height: 2.75rem;
  background: #f7f7f7;
  font-weight: 500;
  align-items: center;
  padding-left: 1.5rem;
  grid-template-columns: 4% 1fr;
  margin-top: -0.3rem;
}

//
.tableHead {
  display: grid;
  grid-template-columns: 40% 45% 10% 5%;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 2.2rem;
}
.tableHead {
  height: auto;
  font-size: 13px;
  border-bottom: 1px solid #4fb1eb;
  padding-bottom: 0.3rem;
}

.tableRow {
  display: grid;
  grid-template-columns: 40% 45% 10% 5%;
  padding: 0.5rem 1rem;
}

.delete {
  cursor: pointer;
}

.files {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 3rem;
  border-radius: 0.9rem;
  cursor: pointer;

  & svg {
    height: 1.4rem;
  }
}

.aosr {
  cursor: pointer;
}
