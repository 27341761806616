.modal {
  width: 82vw;
}

.subtitle {
  padding: 0 1rem 0 0;
  padding-bottom: 1rem;
  color: #696969;
}

.totalsRow {
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.75rem;
  padding: 0 1rem;
}

.total {
  display: flex;
  flex-basis: 50%;
  background: #fafafa;
  height: 2.25rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0 0.75rem;

  & div {
    font-weight: 600;
    color: #696969;
  }

  & span {
    color: #4fb1eb;
    font-weight: 600;
  }
}

.cols[class] {
  display: grid;
  grid-template-columns: 3% 17% 9% 9% 9% 18% 12% 16%;
  grid-column-gap: 1%;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tableHeader {
  height: 2.5rem;
  color: #696969;
  font-weight: 600;
  white-space: nowrap;
  border-bottom: 1px solid #4fb1eb;
  background-color: #fafafa;
}

.indexHeader {
  text-align: right;
  padding-right: 1rem;
}

.add {
  padding: 1rem 1.5rem;
}

.row {
  position: relative;
  height: 4.75rem;
  border-bottom: 1px solid #ebebeb;

  &:hover {
    & .actions {
      display: flex;
    }
  }

  & > div {
    padding-right: 0.5rem;

    &:last-child {
      padding-right: 0;
    }
  }
}

.table {
  max-height: 45vh;
  overflow-y: auto;
  font-weight: 500;
}

.index {
  text-align: right;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.actions {
  display: none;
  justify-content: end;
  position: absolute;
  right: 1rem;
}

.spinner {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  text-align: center;
}

.createdAt {
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  padding-right: 0.5rem;
  column-gap: 0.25rem;
}

.time {
  color: #696969;
}

.hour {
  margin-right: 0.5rem;
}

.empty {
  color: #a4a4a4;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 8rem;
  padding-top: 1rem;
}

.day {
  display: flex;
  gap: 0.5em;
}

.cost {
  white-space: normal;
}
