.radioSquares {
  //padding: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  border-radius: 8px;
  //background: var(--gray-gray-2, #f4f4f4);
  margin-bottom: 1.25rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
}

.container {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.element {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  min-width: 100%;
  cursor: pointer;
  font-size: 0.875rem;
  height: 4.125rem;
  border-radius: 0.5rem;
  background-color: #efefef;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
}

.elementName {
  &::first-letter {
    text-transform: capitalize;
  }
}

$circleSize: 1.25rem;

.circle {
  width: $circleSize;
  height: $circleSize;
  min-height: $circleSize;
  max-height: $circleSize;
  min-width: $circleSize;
  max-width: $circleSize;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #4fb1eb;
  position: relative;
}

.activeElement {
  background-color: #d9f4ff;
  border: 1px solid #4fb1eb;

  .circle {
    background-image: radial-gradient(#4b4b4b 45%, transparent 46%, transparent 100%);
  }
}
