.sectionTitleClassName {
  padding: 0.5rem 0;
  border-bottom: 1px solid #f4f4f4;
  font-weight: 400;
}

.subSectionTitleClassName {
  padding: 0.5rem 0;
  font-weight: 400;
  margin-bottom: 0.5rem;
  white-space: normal;
  width: 100%;
}

.checkbox {
  margin-right: 1rem;
  font-size: 1rem;
}

.row {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: space-between;
}

.rowName {
  margin: 0;
}

.subSection {
  padding-left: 1rem;
}
